import api from "!../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@4.7.0_webpack-dev_m4dyhqfahwyffrvms3ro3zuiqa/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@4.7.0_webpack-dev-s_qcbrgvgimykljf2lbgsxaxphvu/node_modules/css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../node_modules/.pnpm/postcss-loader@4.3.0_postcss@8.4.31_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@_ck5lfy3xxjwaa3nswjq72bukoa/node_modules/postcss-loader/dist/cjs.js!./tailwind-utilities.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};