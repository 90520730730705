<template>
  <header :class="headerStyle">
    <div class="w-full max-w-[1172px] px-[16px] py-[16px] md:py-[24px] mx-auto">
      <HStack class="justify-between items-center">
        <a href="/" class="text-inherit" aria-label="Homepage">
          <Logo aria-hidden="true" :title="`${brandName} logo`" class="h-[30px] md:h-[40px] w-[138px] md:w-[184px]" />
        </a>

        <div class="hidden md:!block">
          <TrustPilotRating
            v-if="showTrustPilot && !isDe"
            :brand-name="brandName"
            :trustpilot-link="trustpilotLink"
            :image-host="imageHostUrl"
            :score="trustPilotScore"
            :colour-scheme="trustpilotRatingColourScheme"
            :review-count="trustPilotReviewCount"
            text-size="medium"
            score-variant="score-only"
            stars-sizing="small"
          />
        </div>

        <a :href="`tel:${contactNumber}`" class="text-inherit">
          <HStack gap="2" class="items-center">
            <div
              class="flex items-center justify-center w-[30px] md:w-[40px] h-[30px] md:h-[40px]"
              :class="phoneIconStyle"
            >
              <PhoneIcon class="w-[13px] md:w-[18px] h-[13px] md:h-[18px]" />
            </div>
            <VStack class="text-left hidden md:!block">
              <p v-if="brandName !== 'rocketrx' && !isDe" class="m-0 text-[14px]">Call customer care</p>
              <p class="m-0 font-medium text-[16px]">{{ contactNumber }}</p>
            </VStack>
          </HStack>
        </a>
      </HStack>
      <div class="block md:!hidden mt-[5px]">
        <TrustPilotRating
          v-if="showTrustPilot && !isDe"
          :brand-name="brandName"
          :trustpilot-link="trustpilotLink"
          :image-host="imageHostUrl"
          :score="trustPilotScore"
          :colour-scheme="trustpilotRatingColourScheme"
          text-size="medium"
          score-variant="score-only"
          stars-sizing="small"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import HStack from "@/Layout/Stack/HStack.vue";
import VStack from "@/Layout/Stack/VStack.vue";

import TrustPilotRating from "../Reviews/components/TrustPilot/TrustPilotRating.vue";

import MedexpressLogo from "../../resources/logos/medexpress-logo.svg?inline";
import ZiphealthLogo from "../../resources/logos/ziphealth-logo.svg?inline";
import RocketrxLogo from "../../resources/logos/rocketrx-logo.svg?inline";
import PhoneIcon from "../../resources/phone-outline.svg?inline";
import { ColourScheme } from "../Reviews/components/TrustPilot/types";

import "../../stylesheets/common/tailwind-utilities.css";

const props = defineProps<{
  contactNumber: string;
  showTrustPilot: boolean;
  trustPilotScore: number;
  trustPilotReviewCount: number;
  imageHostUrl: string;
  brandName: string;
  isDe: boolean;
}>();

const logos = {
  medexpress: MedexpressLogo,
  ziphealth: ZiphealthLogo,
  rocketrx: RocketrxLogo,
};

const trustpilotLinks = {
  medexpress: "https://uk.trustpilot.com/review/www.medexpress.co.uk",
  ziphealth: "https://us.trustpilot.com/review/www.ziphealth.co",
  rocketrx: "https://us.trustpilot.com/review/www.rocketrx.com",
};

const Logo = logos[props.brandName] || MedexpressLogo;
const headerStyle =
  props.brandName === "rocketrx" ? ["bg-[#E9E2C5]", "text-[#272A3F]"] : ["bg-[#003F82]", "text-white"];
const phoneIconStyle =
  props.brandName === "rocketrx" ? [] : ["border-white", "border-[3px]", "rounded-full", "border-solid"];
const trustpilotRatingColourScheme: ColourScheme = props.brandName === "rocketrx" ? "rocket" : "light";
const trustpilotLink = trustpilotLinks[props.brandName];
</script>
