export default function scoreToStars(score: number): number {
  if (score >= 4.8) {
    return 5;
  } else if (score >= 4.3) {
    return 4.5;
  } else if (score >= 3.8) {
    return 4;
  } else if (score >= 3.3) {
    return 3.5;
  } else if (score >= 2.8) {
    return 3;
  } else if (score >= 2.3) {
    return 2.5;
  } else if (score >= 1.8) {
    return 2;
  } else if (score >= 1.3) {
    return 1.5;
  }
  return 1;
}
