import Vue from "vue";

import MinimalNavBar from "./MinimalNavBar.vue";
document.addEventListener("DOMContentLoaded", () => {
  const selector = "#nav-bar-params";
  const paramsElement = document.querySelector(selector);

  if (!paramsElement) {
    console.error(`Cannot find parameters for nav bar using selector ${selector}`);
    return;
  }

  const jsonData = JSON.parse(paramsElement.innerHTML);

  new Vue({
    el: "#nav-bar-mount",
    render: function (createElement) {
      return createElement(MinimalNavBar, {
        props: {
          ...jsonData,
          brandName: process.env.APP_THEME,
          isDe: process.env.REGION === "de",
        },
      });
    },
  });
});
