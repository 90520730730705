import api from "!../../../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@4.7.0_webpack-dev_m4dyhqfahwyffrvms3ro3zuiqa/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@4.7.0_webpack-dev-s_qcbrgvgimykljf2lbgsxaxphvu/node_modules/css-loader/dist/cjs.js??clonedRuleSet-3.use[1]!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.2.47_babel-core@7.0.0-bridge.0_@babel+core@7.20.12__cs_her3pwfqzvibhbxzltpeij5lhm/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../node_modules/.pnpm/sass-loader@10.4.1_sass@1.58.0_webpack@5.95.0_webpack-cli@5.0.1_webpack-bundle-analyzer@4.7.0_cj7vu45cvky3z7xdooiwdkeirm/node_modules/sass-loader/dist/cjs.js!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.2.47_babel-core@7.0.0-bridge.0_@babel+core@7.20.12__cs_her3pwfqzvibhbxzltpeij5lhm/node_modules/vue-loader/lib/index.js??vue-loader-options!./TrustPilotRating.vue?vue&type=style&index=1&id=2a4eb3a6&prod&lang=scss&scoped=true";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};