import { render, staticRenderFns } from "./TrustPilotStars.vue?vue&type=template&id=d32f19ba&scoped=true"
import script from "./TrustPilotStars.vue?vue&type=script&setup=true&lang=ts"
export * from "./TrustPilotStars.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TrustPilotStars.vue?vue&type=style&index=0&id=d32f19ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.2.47_babel-core@7.0.0-bridge.0_@babel+core@7.20.12__cs_her3pwfqzvibhbxzltpeij5lhm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d32f19ba",
  null
  
)

export default component.exports