<template>
  <img :src="imageUrl" :class="`dimensions-logo-${sizing}`" alt="TrustPilot Logo" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ColourScheme, Sizing } from "./types";

const props = withDefaults(defineProps<{ colourScheme: ColourScheme; sizing: Sizing; imageHost: string }>(), {
  colourScheme: "dark",
});

const imageUrl = computed(() => {
  if (props.colourScheme === "light") {
    return `${props.imageHost}/medexpress-resources/img/logos/trustpilot-logo-white.png`;
  }
  return `${props.imageHost}/medexpress-resources/img/logos/trustpilot-logo.png`;
});
</script>

<style scoped>
.dimensions-logo-extra-small {
  @apply h-[18px];
}

.dimensions-logo-small {
  @apply h-[20px];
}

.dimensions-logo-medium {
  @apply h-[22px] lg:h-[25px];
}
</style>
