<template>
  <a
    :href="trustpilotLink"
    target="_blank"
    rel="noopener noreferrer"
    :class="(textColor, { 'w-full flex items-center': isNavbarVariant })"
    :aria-label="`${brandName} TrustPilot`"
  >
    <div class="flex gap-[12px] items-center w-[55%]" :class="{ 'w-max': isNavbarVariant }">
      <TrustPilotLogo :image-host="imageHost" :sizing="logoSizing" :colour-scheme="colourScheme" />
      <TrustPilotStars :sizing="starsSizing" :stars="stars" :image-host="imageHost" />
      <p
        v-if="withScoreOutOfFive && scoreVariant === 'score-out-of-five'"
        class="my-0 whitespace-nowrap"
        :class="[textColor, fontSize]"
      >
        <span>{{ score }}</span> / 5
      </p>
      <p v-if="scoreVariant === 'score-only'" class="my-0 whitespace-nowrap" :class="[textColor, fontSize]">
        {{ score }}
      </p>
      <span v-if="reviewCount" :class="[textColor, fontSize]">•</span>
      <p v-if="reviewCount" class="my-0 whitespace-nowrap" :class="[textColor, fontSize]">
        {{ reviewCount.toLocaleString() }} reviews
      </p>
    </div>
  </a>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TrustPilotLogo from "./TrustPilotLogo.vue";
import TrustPilotStars from "./TrustPilotStars.vue";
import scoreToStars from "./ScoreToStars";
import { ColourScheme, ScoreVariant, Sizing } from "./types";

const props = withDefaults(
  defineProps<{
    imageHost: string;
    score: number;
    brandName?: string;
    trustpilotLink?: string;
    withScoreOutOfFive?: boolean;
    colourScheme?: ColourScheme;
    reviewCount?: number;
    textSize?: Exclude<Sizing, "extra-small">;
    scoreVariant?: ScoreVariant;
    starsSizing?: Sizing;
    logoSizing?: Sizing;
    variant?: "navbar";
  }>(),
  {
    imageHost: "https://www.medexpress.co.uk/images",
    brandName: "MedExpress",
    trustpilotLink: "https://uk.trustpilot.com/review/www.medexpress.co.uk",
    withScoreOutOfFive: true,
    colourScheme: "dark",
    textSize: "small",
    scoreVariant: "score-out-of-five",
    starsSizing: "medium",
    logoSizing: "medium",
    variant: undefined,
  }
);

const stars = computed(() => {
  return scoreToStars(props.score);
});

const fontSize = computed(() => {
  if (props.textSize === "small") {
    return "text-[13px]";
  }
  return "text-[16px]";
});

const textColor = computed(() => {
  if (props.colourScheme === "dark") {
    return "text-[#222222]";
  }

  if (props.colourScheme === "rocket") {
    return "text-[#272A3F]";
  }

  return "text-white";
});

const isNavbarVariant = computed(() => {
  return props.variant === "navbar";
});
</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

<style lang="scss" scoped>
@import "@theme/Main.scss";
</style>
